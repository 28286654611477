<template>
  <v-row no-gutters class="mb-8 px-8">
    <v-col
      xs="0"
      sm="0"
      md="0"
      lg="1"
      xl="1"
    >
    </v-col>

    <v-col
      xs="12"
      sm="12"
      md="12"
      lg="10"
      xl="10"
      class="pt-8"
    >
      <div class="d-flex mb-7">
        <div 
          class="text-h3 font-weight-light" 
          style="
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          "
        >
          {{ $t('title.marketMonitor') }}
        </div>
        <v-spacer></v-spacer>
        <div class="font-weight-light">
          <!-- {{ now }} -->
        </div>
      </div>

      <v-row
        no-gutters
        class="d-flex align-center pt-12 pb-10 mb-16"
      >
      <!-- Temporary removal of dollar radar -->
        <v-col
          v-if="false"
          xs="12"
          sm="12"
          md="12"
          lg="4"
          xl="4"
          class="mb-8"
        >
          <v-row class="align-center">
            <v-col cols="12">
              <v-sheet
                color="transparent"
                class="d-flex flex-column align-center justify-center"
              >
                <div class="d-flex align-center py-6 px-8" style="width: 100%">
                  <div class="text--secondary text-h5 font-weight-bold mr-8">
                    {{ $t('marketMonitor.dollarRadar.title') }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    class="disable-focus"
                    rounded
                    fab
                    small
                    color="primaryTransp"
                    @click="fetchDollarRadar()"
                    :loading="loadingFetchDollar"
                    :retain-focus-on-click="false"
                  >
                    <v-icon color="primary">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </div>
                <div class="px-8" style="width: 100%">
                  <v-divider></v-divider>
                </div>
                <div class="py-6 px-8 d-flex align-center" style="width: 100%">
                  <div class="mr-2">
                    {{ $t('marketMonitor.period.title') }}: 
                  </div>
                  <v-chip-group
                    v-model="selectedDollarPeriod"
                    mandatory
                    column
                  >
                    <v-chip
                      class="text-caption font-weight-black"
                      color="#212121"
                      text-color="#BDBDBD"
                      active-class="primaryTransp primary--text"
                      v-for="(dollarPeriod, index) in dollarPeriods"
                      :key="index"
                      :value="dollarPeriod"
                      :disabled="dollarPeriod.disabled"
                    >
                      {{ dollarPeriod.text }}
                    </v-chip>
                  </v-chip-group>
                </div>
                <div class="px-8" style="width: 100%">
                  <v-divider></v-divider>
                </div>
                <!-- Dollar Today -->
                <div class="d-flex py-6 px-8 justify-center" style="width: 100%">
                  <div class="mx-4 d-flex flex-column">
                    <div class="d-flex justify-center text-body-1 text--secondary"> 
                        <span> {{ $t('marketMonitor.dollar') }} </span>&nbsp;        <span class="font-weight-bold"> 
                          {{ $t('timestamp.today') }}
                        </span> 
                    </div>
                    <div class="d-flex align-center" style="margin-left: -82px">
                      <v-icon style="font-size: 58px" color="#424242">
                        mdi-calendar-today
                      </v-icon>
                      &nbsp;
                      <div class="text-h4 ml-4 font-weight-bold">
                        {{ dollarByPeriod.dollar_today }}
                      </div>
                      </div>
                  </div>
                </div>
                <!-- Dollar Trend -->
                <div 
                  v-if="dollarByPeriod.dollar_trend.toLocaleLowerCase().includes('strong')"
                  class="d-flex pb-6 pt-0 px-8 align-center justify-center" 
                  style="width: 100%"
                >
                  <div class="mx-4 d-flex flex-column">
                    <div class="d-flex justify-center text-body-1 text--secondary">
                      <span> {{ $t('marketMonitor.dollar') }} </span>&nbsp;<span class="font-weight-bold"> {{ $t('marketMonitor.trend') }} </span>
                    </div>
                    <div class="d-flex align-center" style="margin-left: -82px">
                      <v-icon style="font-size: 58px" color="primary">
                        {{ dollarByPeriod.dollar_trend | trendIcon }}
                      </v-icon>
                      &nbsp;
                      <div class="text-h4 ml-4 font-weight-bold">
                        {{ trendLabel(dollarByPeriod.dollar_trend) }}
                      </div>
                    </div>
                  </div> 
                </div>

                <div
                  v-else
                  class="d-flex py-6 px-8 justify-center" 
                  style="width: 100%"
                >
                  <div class="mx-4 d-flex">
                    <div 
                      class="d-flex align-center" 
                      :style="{
                        'margin-left': (dollarByPeriod.dollar_trend.toLocaleLowerCase().includes('neutral') && isPtBRLocale === true) ||
                          (dollarByPeriod.dollar_trend.toLocaleLowerCase().includes('sell') && isPtBRLocale === true)
                          ? '-52px' : '-82px'
                      }"
                    >
                      <v-icon style="font-size: 58px" color="primary">
                        {{ dollarByPeriod.dollar_trend | trendIcon }}
                      </v-icon>
                      &nbsp;
                      <div 
                        class="text-h4 font-weight-bold" 
                        :style="{
                          'margin': (dollarByPeriod.dollar_trend.toLocaleLowerCase().includes('neutral') ||
                            (dollarByPeriod.dollar_trend.toLocaleLowerCase().includes('sell') && isPtBRLocale === true))
                          ? '0 10px' : '0px 46.5px 0px 23.5px'
                        }"
                      >
                      {{ trendLabel(dollarByPeriod.dollar_trend) }}
                      </div>
                      <div class="ml-4 d-flex flex-column text-body-1 text--secondary"> 
                        <span> {{ $t('marketMonitor.dollar') }} </span>
                        <span class="font-weight-bold">
                          {{ $t('marketMonitor.trend') }}
                        </span> 
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Dollar Target -->
                <div class="d-flex pb-6 pt-0 px-8 align-center justify-center" style="width: 100%">
                  <div class="mx-4 d-flex flex-column">
                    <div class="d-flex justify-center text-body-1 text--secondary"> 
                        <span> {{ $t('marketMonitor.dollar') }} </span>&nbsp;
                        <span class="font-weight-bold"> 
                          {{ $t('marketMonitor.target') }} 
                        </span> 
                    </div>
                    <div class="d-flex align-center" style="margin-left: -82px">
                      <v-icon style="font-size: 58px" color="#424242">
                        mdi-bullseye
                      </v-icon>
                      &nbsp;
                      <div class="text-h4 ml-4 font-weight-bold">
                        {{ dollarByPeriod.dollar_target }}
                      </div>
                      </div>
                  </div>
                </div>
                <div class="px-8" style="width: 100%">
                  <v-divider></v-divider>
                </div>
                <div 
                  class="d-flex justify-center py-9 text-h4 font-weight-bold"
                  style="width: 100%; flex-wrap: wrap"
                >
                  <span style="text-wrap: nowrap">{{ $t('marketMonitor.goodTo') }}</span> 
                  <span 
                    v-for="(part, index) in bestChoice(dollarByPeriod).parts" 
                    :key="index" 
                    :class="part.class"
                  >
                    &nbsp;{{ part.text }}
                  </span>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
        <v-divider v-if="false" vertical class="d-none d-lg-flex"></v-divider>
        <v-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="d-flex flex-column mb-8"
        >
          <div v-if="sparklineDataIntraday && sparklineDataDaily" class="text-caption font-weight-black d-flex justify-center">
            <v-chip-group
              v-model="selectedChart"
              mandatory
              @change="filterData(currentFilter)"
              show-arrows
            >
              <v-chip
                color="#212121"
                text-color="#BDBDBD"
                active-class="primaryTransp primary--text"
                v-for="(chartName, index) in chartNames"
                :key="index"
                :value="chartName"
              >
                {{ chartName }}
              </v-chip>
            </v-chip-group>
          </div>
          <div v-if="!sparklineDataIntraday || !sparklineDataDaily" class="loading-indicator d-flex justify-center align-center" style="height: 500px">
            <v-progress-circular indeterminate color="primary" :size="110" :width="7"></v-progress-circular>
            <span class="ml-3 text-h4">{{ $t('extras.loadingDot') }} </span>
          </div>
          <div v-if="sparklineDataIntraday && sparklineDataDaily" class="mb-2" style="overflow-x: auto; overflow-y: hidden">
            <v-chart
              :option="sparkline"
              class="chart"
              autoresize
            ></v-chart>
          </div>
          <div
            v-if="sparklineDataIntraday && sparklineDataDaily && isWeekendOrHoliday && currentFilter === 'day'"
            style="position: relative; display: flex; align-items: center; max-width: 300px"
          >
            <span
              class="text--disabled font-weight-light"
              :style="{
                'font-size': '12px',
                position: 'absolute',
                left: '80px',
                top: '5px'
              }"
            >
              We don't have 1D data during weekends or holidays,
              so this data is from the last business day.
            </span>
          </div>
          <div v-if="sparklineDataIntraday && sparklineDataDaily" class="ml-12 d-flex justify-center">
            <v-btn-toggle v-model="currentFilter" mandatory @change="filterData(currentFilter)">
              <v-btn value="day">{{ $t('timestamp.oneDay') }}</v-btn>
              <v-btn value="week">{{ $t('timestamp.oneWeek') }}</v-btn>
              <v-btn value="month">{{ $t('timestamp.oneMonth') }}</v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
      <div class="pb-10 mb-16">
        <div class="text-h5 font-weight-bold pb-6">
          <span>{{ $t('marketMonitor.news') }}</span>
        </div> 
        <div v-if="getSlidesNews">
          <news-chart :slides="getSlidesNews"></news-chart>
        </div>
      </div>
      <div class="pb-10 mb-16">
        <div class="text-h5 font-weight-bold pb-6">
          <span>{{ $t('marketMonitor.crossQuotes') }}</span>
        </div>
        <div>
          <cross-quote-table></cross-quote-table>
        </div>
      </div>
      <div class="pb-10 mb-16">
        <div class="text-h5 font-weight-bold pb-6">
          <span>{{ $t('marketMonitor.economicCalendar') }}</span>
        </div>
        <div>
          <economic-calendar-chart :events="events"></economic-calendar-chart>
        </div>
      </div>
    </v-col>

    <v-col
      xs="0"
      sm="0"
      md="0"
      lg="1"
      xl="1"
    >
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "pinia"
import { useAlgoRFQStore, useUtilsStore } from "./../store/index"
import * as utils from "./../utils.js"
import NewsChart from "./../components/NewsChart.vue"
import CrossQuoteTable from "./../components/CrossQuoteTable.vue"
import EconomicCalendarChart from "./../components/EconomicCalendarCharts.vue"
import VChart from "vue-echarts"
import { use } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  TooltipComponent,
  TitleComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

use([
  TooltipComponent,
  TitleComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer
])

export default {
  filters: {
    trendIcon (value) {
      const trends = {
        STRONG_SELL: "mdi-trending-down",
        STRONG_BUY: "mdi-trending-up",
        BUY: "mdi-trending-up",
        SELL: "mdi-trending-down",
        NEUTRAL: "mdi-trending-neutral"
      }
      return trends[value]
    }
  },
  data() {
    return {
      chartData: null,
      eventSource: undefined,
      interval: null,
      currentWeekStart: null,
      filteredSparklineData: [],
      min: null,
      max: null,
      currentFilter: 'day',
      events: [],
      selectedChart: undefined,
      dollar: {
        "W": {
          "dollar_today": "-",
          "dollar_trend": "-",
          "dollar_target": "-",
          "dollar_best_choice": "-"
        },
        "10D": {
          "dollar_today": "-",
          "dollar_trend": "-",
          "dollar_target": "-",
          "dollar_best_choice": "-"
        },
        "20D": {
          "dollar_today": "-",
          "dollar_trend": "-",
          "dollar_target": "-",
          "dollar_best_choice": "-"
        },
        "1M": {
          "dollar_today": "-",
          "dollar_trend": "-",
          "dollar_target": "-",
          "dollar_best_choice": "-"
        },
      },
      selectedDollarPeriod: { value: "W" },
      loadingFetchDollar: false,
      intervalDaily: null,
      timeoutDaily: null,
      holidaysList: []
    }
  },
  components: {
    VChart,
    NewsChart,
    CrossQuoteTable,
    EconomicCalendarChart
  },
  computed: {
    ...mapState(useUtilsStore, [
      "orangeColor",
      "aliveColor",
    ]),
    ...mapState(useAlgoRFQStore, [
      "getFXMarketChartDaily",
      "getFXMarketChartIntraday",
      "getSlidesNews",
    ]),
    now () {
      return utils.getLocaleFormattedDateTime()
    },
    sparklineDataDaily() {
      if (!this.fxMarketChartDailyData || Object.keys(this.fxMarketChartDailyData).length === 0) {
        return
      }

      let selectedChart = this.selectedChart
      if (this.selectedChart === undefined) {
        selectedChart = this.chartNames[0]
      }

      const selectedSparkline = this.fxMarketChartDailyData[selectedChart]

      const today = new Date()
      const oneMonthAgo = new Date()
      oneMonthAgo.setDate(today.getDate() - 31)

      const filteredDataMonth = []

      for (const key in selectedSparkline) {
        const dateBrl = new Date(key).getTime() + (3 * 60 * 60 * 1000)

        if (dateBrl >= oneMonthAgo.getTime() &&
          dateBrl <= today.getTime() &&
          !this.isHoliday(new Date(dateBrl))) {
            filteredDataMonth.push({
              timestamp: dateBrl,
              value: selectedSparkline[key]['1. open'],
            })
          }
        }

      filteredDataMonth.reverse()
      return {
        filteredDataMonth,
      }
    },
    sparklineDataIntraday() {
      if (!this.fxMarketChartIntradayData || Object.keys(this.fxMarketChartIntradayData).length === 0 || !this.chartNames) {
        return
      }

      let selectedChart = this.selectedChart
      if (this.selectedChart === undefined) {
        selectedChart = this.chartNames[0]
      }

      const selectedSparkline = this.fxMarketChartIntradayData[selectedChart]
      
      const today = new Date()
      const currentDay = today.getDay()
      const currentHour = today.getHours()
      const oneWeekAgo = new Date()
      oneWeekAgo.setDate(today.getDate() - 7)

      let startOfDayBRT, endOfDayBRT

      if (currentHour < 9 || this.isWeekendOrHoliday) {
        const yesterday = new Date(today)
        if (currentDay === 0) {
          yesterday.setDate(today.getDate() - 2)
        } else {
          yesterday.setDate(today.getDate() - 1)
        }
        while (this.isHoliday(yesterday) || yesterday.getDay() === 0 || yesterday.getDay() === 6) {
          yesterday.setDate(yesterday.getDate() - 1)
        }

        startOfDayBRT = new Date(yesterday.setHours(9, 0, 0, 0)).getTime()
        endOfDayBRT = new Date(yesterday.setHours(18, 30, 0, 0)).getTime()
      } else {
        startOfDayBRT = new Date(today.setHours(9, 0, 0, 0)).getTime()
        endOfDayBRT = new Date(today.setHours(18, 30, 0, 0)).getTime()
      }

      const filteredData = []
      const filteredDataWeek = []

      for (const key in selectedSparkline) {
        const dateUtc = new Date(key).getTime()
        const dateBrl = dateUtc - (3 * 60 * 60 * 1000)
        const dateBrlDate = new Date(dateBrl)
        const dayOfWeek = dateBrlDate.getDay()
        const hourOfDay = dateBrlDate.getHours()
        const minutesOfHour = dateBrlDate.getMinutes()

        if (dateBrl >= startOfDayBRT && dateBrl <= endOfDayBRT) {
          filteredData.push({
            timestamp: dateBrl,
            value: selectedSparkline[key]['1. open'],
          })
        }
        
        if (dateBrl >= oneWeekAgo.getTime() &&
          dateBrl <= today.getTime() &&
          dayOfWeek > 0 && dayOfWeek < 6 &&
          !this.isHoliday(new Date(dateBrl)) &&
          (hourOfDay >= 9 && hourOfDay < 18 || (hourOfDay === 18 && minutesOfHour <= 30))) {
          filteredDataWeek.push({
            timestamp: dateBrl,
            value: selectedSparkline[key]['1. open'],
            })
          }
        }

      filteredData.reverse()
      filteredDataWeek.reverse()

      return {
        filteredData,
        filteredDataWeek,
      }
    },
    minMax() {
      if (!this.filteredSparklineData) {
        return { min: 0, max: 0 }
      }
      const values = this.filteredSparklineData.map(point => parseFloat(point.value))
      const min = Math.min(...values)
      const max = Math.max(...values)
      const interval = (max - min)
      const adjustedMin = parseFloat(Math.max(min - (interval * 1.1), 0)).toFixed(4)
      const adjustedMax = parseFloat(max + (interval * 1.1)).toFixed(4)
      return { min: adjustedMin, max: adjustedMax }
    },
    // xAxisData() {
    //   return this.filteredSparklineData.map(dataPoint => {
    //     const date = new Date(dataPoint.timestamp)
    //     if (this.currentFilter === 'day') {
    //       return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    //     } else {
    //       return date.getDate()
    //     }
    //   })
    // },
    xAxisInterval() {
      const dataLength = this.filteredSparklineData.length
      const startTime = this.filteredSparklineData[0].timestamp
      const endTime = this.filteredSparklineData[dataLength - 1].timestamp
      const timeSpan = endTime - startTime
      let minInterval

      switch (this.currentFilter) {
        case 'day':
          if (timeSpan <= 60 * 60 * 1000) {
            minInterval = 5 * 60 * 1000
          } else if (timeSpan <= 4 * 60 * 60 * 1000) {
            minInterval = 10 * 60 * 1000
          } else {
            minInterval = 30 * 60 * 1000
          }
          return minInterval
        // case 'week':
        //   minInterval = 24 * 60 * 60 * 1000
        //   return minInterval
        case 'month':
          minInterval = 24 * 60 * 60 * 1000
          return minInterval
        default:
          return 0
      }
    },
    sparkline() {
      if (!this.filteredSparklineData || this.filteredSparklineData.length === 0) return
      const options = JSON.parse(JSON.stringify(this.sparklinesOptions))

      const categories = this.filteredSparklineData.map(dataPoint =>
        this.formatDate(dataPoint.timestamp, this.currentFilter)
      )
      const values = this.filteredSparklineData.map(dataPoint => parseFloat(dataPoint.value))

      options.xAxis.data = categories
      options.series[0].data = values

      if (this.currentFilter === 'week') {
        let lastDay = ''
        const lastIndex = categories.lengh - 1

        options.xAxis.axisLabel.formatter = (value, index) => {
          const currentDay = value.split(' ')[0]

          if (currentDay !== lastDay || index === lastIndex) {
            lastDay = currentDay
            return currentDay
          }

          return ''
        }
      }

      options.tooltip.formatter = (params) => {
        const dataPoint = params[0]
        const rawTimestamp = this.filteredSparklineData[dataPoint.dataIndex]?.timestamp
        const formattedDate = this.formatDate(rawTimestamp, this.currentFilter, true, true)
        const value = dataPoint.value;
        return `
          ${formattedDate}<br>${dataPoint.marker} ${dataPoint.seriesName}: <b>${value}</b>
        `
      }

      options.axisPointer = {
            label: {
              backgroundColor: '#777',
              formatter: (params) => categories[params.value]
            }
          }
          
      return options
    },
    sparklinesOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            snap: true
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            formatter: value => value
          },
          axisLine: { show: true },
          axisTick: { 
            show: true,
            alignWithLabel: true
          },
        },
        yAxis: [
          {
            type: 'value',
            ...this.minMax,
            splitNumber: 8,
            splitLine: { show: false },
            position: 'left'
          },
          {
            type: 'value',
            ...this.minMax,
            splitNumber: 8,
            splitLine: { show: false },
            position: 'right'
          },
        ],
        series: [
          {
            name: 'Data',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: this.changeValue > 0 ? this.aliveColor : this.orangeColor,
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: this.changeValue > 0 ? this.aliveColor : this.orangeColor,
                  },
                  {
                    offset: 1,
                    color: 'rgba(0, 0, 0, 0)',
                  },
                ],
              },
            },
            data: []
          },
        ],
      }
    },
    changeValue() {
      const data = this.filteredSparklineData
      const length = data.length
      const last = data[length - 1].value
      const first = data[0].value
      const result = last - first
      return Number(result.toFixed(6))
    },
    chartNames () {
      if (!this.getFXMarketChartDaily) return
      return this.getFXMarketChartDaily.tickers
    },
    dollarByPeriod () {
      return this.dollar[this.selectedDollarPeriod.value]
    },
    outOfWeek () {
      const dayOfWeek = new Date().getDay()
      if (dayOfWeek > 3) {
        return true
      }
      return false
    },
    dollarPeriods () {
      return [
        { text: this.$t('marketMonitor.period.endWeek'), value: "W" },
        { text: this.$t('marketMonitor.period.tenDays'), value: "10D" },
        { text: this.$t('marketMonitor.period.twentyDays'), value: "20D" },
        { text: this.$t('marketMonitor.period.oneMonth'), value: "1M" }
      ]
    },
    isPtBRLocale() {
      return this.$i18n.locale === "pt"
    },
    fxMarketChartDailyData () {
      if (!this.getFXMarketChartDaily) return []
      return this.getFXMarketChartDaily.data
    },
    fxMarketChartIntradayData () {
      if (!this.getFXMarketChartIntraday) return []
      return this.getFXMarketChartIntraday.data
    },
    // isWeekend () {
    //   const today = new Date()
    //   const currentDay = today.getDay()

    //   if (currentDay === 0 || currentDay === 6) {
    //     return true
    //   }
    //   return false
    // },
    isWeekendOrHoliday() {
      const today = new Date()
      const currentDay = today.getDay()
      const todayDate = today.toISOString().split('T')[0]
      
      if (currentDay === 0 || currentDay === 6) {
        return true
      }
      
      for (let holiday of this.holidaysList) {
        if (holiday.date === todayDate) {
          return true
        }
      }
      return false
    }
  },
  mounted() {
    if (this.outOfWeek) {
      this.selectedDollarPeriod = this.dollarPeriods.filter(x => x.value === "10D")[0]
      const index = this.dollarPeriods.map(x => x.value).indexOf("W")
      this.dollarPeriods[index]["disabled"] = true
    }
    this.fetchHolidaysList()
    this.filterData(this.currentFilter)
    this.fetchDollarRadar()
  },
  beforeDestroy () {
    clearInterval(this.intervalDaily)
    clearTimeout(this.timeoutDaily)
  },
  methods: {
    ...mapActions(useAlgoRFQStore, [
      "getFxMarketEconomicCalendar",
      "getDollarRadar",
      "getHolidaysList"
    ]),
    fetchEconomicCalendar() {
      const from_date = this.getDate(0)
      const to_date = this.getDate(10)

      const countries = "US,EU,BR"

      this.getFxMarketEconomicCalendar(from_date, to_date, countries)
        .then((response) => {
          const data = response.data
          this.events = data.result
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // listenFeed() {
    //   const sparklinesEvent = "sparklines"
    //   const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/feed`
    //   this.eventSource = new EventSource(`${url}`)
    //   this.eventSource.addEventListener(sparklinesEvent, event => {
    //     const data = JSON.parse(event.data)
    //     this.sparklineList = data
    //     this.filterData(this.currentFilter)
    //   })
    // },
    filterData(period) {
      const monthData = this.sparklineDataDaily
      const data = this.sparklineDataIntraday

      this.currentFilter = period

      if (period === 'day') {
        this.filteredSparklineData = data['filteredData']
      } else if (period === 'month') {
        this.filteredSparklineData = monthData['filteredDataMonth']
      } else {
        this.filteredSparklineData = data['filteredDataWeek']
      }
    },
    fetchDollarRadar() {
      this.loadingFetchDollar = true
      this.getDollarRadar()
        .then((response) => {
          const data = response.data
          this.dollar = data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingFetchDollar = false
        })
    },
    trendLabel (value) {
      const trends = {
        STRONG_SELL: this.$t('marketMonitor.dollarRadar.strongSell'),
        STRONG_BUY: this.$t('marketMonitor.dollarRadar.strongBuy'),
        BUY: this.$t('marketMonitor.dollarRadar.buy'),
        SELL: this.$t('marketMonitor.dollarRadar.sell'),
        NEUTRAL: this.$t('marketMonitor.dollarRadar.neutral')
      }
      return trends[value]
    },
    bestChoice(dollarPeriod) {
      if (dollarPeriod.dollar_best_choice === 'SELL') {
        return {
          parts: [
            { text: this.$i18n.t('side.sell').toUpperCase(), class: 'orangeColor--text' }
          ]
        }
      }
      else if (dollarPeriod.dollar_best_choice === 'BUYSELL') {
        return {
          parts: [
            { text: this.$i18n.t('side.buy').toUpperCase(), class: 'alive--text' },
            { text: this.$i18n.t('extras.or'), class: '' },
            { text: this.$i18n.t('side.sell').toUpperCase(), class: 'orangeColor--text' }
          ]
        }
      }
      else {
        return {
          parts: [
            { text: this.$i18n.t('side.buy').toUpperCase(), class: 'alive--text' }
          ]
        }
      }
    },
    getDate(days) {
      const date = new Date()
      date.setDate(date.getDate() + days)

      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    formatDate(value, filter, forTooltip = false) {
      const date = new Date(value)
      const labelDate = [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
      ]
      const labelHour = [
        date.getHours().toString().padStart(2, '0'),
        date.getMinutes().toString().padStart(2, '0'),
      ]
      const labelYear = date.getFullYear();

      if (forTooltip) {
        if (filter === 'day' || filter === 'week') {
          return `
          ${labelYear}-${labelDate.join('-')} <br>
          ${labelHour.join(':')}
          `
        }
        if (filter === 'month') {
          return `${labelYear}-${labelDate.join('-')}`
        }
      }

      if (filter === 'day') {
        return labelHour.join(':');
      } else if (filter === 'week') {
        return labelDate.join('/');
      } else {
        return labelDate.join('/');
      }
    },
    fetchHolidaysList() {
      this.getHolidaysList()
        .then((response) => {
          this.holidaysList.push(...response.holidays)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    isHoliday (date) {
        if (!this.holidaysList || this.holidaysList.length === 0) return false
        const formattedDate = date.toISOString().split('T')[0]
        return this.holidaysList.some(holiday => holiday.date === formattedDate)
    }
  }
}
</script>

<style scoped>
  .chart {
    max-width: 1940px;
    min-width: 715px;
    /* width: 1940px; */
    width: 100%;
    height: 500px;
    box-sizing: border-box;
    overflow: hidden
  }

.disable-focus:focus::before {
  opacity: 0 !important
}
</style>
