<template>
  <div class="d-flex" style="min-width: 280px; max-width: 280px;"> <!-- style="max-width: 290px; min-width: 290px;" -->
    <v-carousel
      height="75"
      hide-delimiters
      :cycle="true"
      hide-delimiter-background
      :show-arrows="false"
      :interval="6000"
      class="px-3"
    >
      <!-- show-arrows-on-hover -->
      <v-carousel-item
        v-for="(slide, i) in newsSlides"
        :key="i"
      >
        <!--
        <v-card
          class="mx-auto"
          max-width="290"
          min-width="290"
          outlined
          elevation="0"
          height="75"
          :color="'#000000'"
          style="border: thin solid rgba(255, 255, 255, 0.12);"
          @click="reveal = !reveal"
        >
        -->
          <div class="py-1 px-1 d-flex flex-column" @click="openNews(slide.link)">
            <div class="d-flex">
              <span 
                class="text-body-1 text--primary d-inline-block text-truncate"
                style="max-width: 190px;"
              >
                {{ slide.source }}
              </span>
              <v-spacer></v-spacer>
              <span class="text-caption text--disabled text-no-wrap">
                {{ slide.time_published | formatCustomDate }}
              </span>
            </div>
            <div 
              class="text-caption text--secondary"
              style="max-width: 282px;"
            >
            {{ slide.title.length <= 70 ? slide.title : slide.title.slice(0,70).trim().concat("...") }}
            </div>
          </div>
        <!--
        </v-card>
        -->
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { mapState } from "pinia"
import { useAlgoRFQStore } from "./../store/index"
import { i18n } from "./../plugins/vuetify"

export default {
  data () {
    return {
      reveal: false,
    }
  },
  mounted () {
  },
  filters: {
    formatCustomDate(dateString) {
      let day, month, year, hours, minutes
      
      year = dateString.slice(0, 4)
      month = dateString.slice(4, 6)
      day = dateString.slice(6, 8)
      hours = dateString.slice(9, 11)
      minutes = dateString.slice(11, 13)

      const locale = i18n.locale

      const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}`)
      // const dateOptions = { day: 'numeric', month: 'long' }
      // const formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(date)
      const timeOptions = { 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true, 
      timeZone: 'America/Sao_Paulo'
    }
      const formattedTime = new Intl.DateTimeFormat(locale, timeOptions).format(date)
      
      return `${formattedTime}`
    }
  },
  computed: {
    ...mapState(useAlgoRFQStore, [
      "getSlidesNews",
    ]),
    newsSlides() {
      if (!this.getSlidesNews) {
        return []
      }

      const flattenedSlides = this.getSlidesNews.flatMap(slideGroup => 
        Object.values(slideGroup.feed).filter(slide => {
          const titleOrContent = (slide.title).toLowerCase() + (slide.summary).toLowerCase()
          return !titleOrContent.includes('bitcoin') && !titleOrContent.includes('crypto')
        })
      )

      const filterByCurrency = (currencyCode) => {
        return flattenedSlides.filter(slide => {
          if (slide.ticker_sentiment) {
            return slide.ticker_sentiment.some(tickerData => tickerData.ticker === `FOREX:${currencyCode}`)
          }
          return false
        })
      }

      let forexData = filterByCurrency('BRL') 
      if (forexData.length < 4) {
        forexData = forexData.concat(filterByCurrency('USD').slice(0, 4 - forexData.length))
      }
      if (forexData.length < 4) {
        forexData = forexData.concat(filterByCurrency('EUR').slice(0, 4 - forexData.length))
      }
      if (forexData.length < 4) {
        forexData = forexData.concat(filterByCurrency('JPY').slice(0, 4 - forexData.length))
      }

      forexData = forexData.slice(0, 4)

      forexData.sort((a, b) => {
        const dateA = new Date(
          `${a.time_published.slice(0, 4)}-${a.time_published.slice(4, 6)}-${a.time_published.slice(6, 8)}T${a.time_published.slice(9, 11)}:${a.time_published.slice(11, 13)}`
        )
        const dateB = new Date(
          `${b.time_published.slice(0, 4)}-${b.time_published.slice(4, 6)}-${b.time_published.slice(6, 8)}T${b.time_published.slice(9, 11)}:${b.time_published.slice(11, 13)}`
        )
        return dateB - dateA
      })

      return forexData
    },
  },
  methods: {
    openNews (url) {
      window.open(url, "_blank")
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 10
}
</style>