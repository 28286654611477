<template>
  <v-app-bar
    app
    :height="115"
    color="black"
  >
    <div
      style="width: 100%;"
      class="d-flex flex-column fill-height"
    >
      <div class="d-flex text-caption text--disabled font-weight-regular">
        Joao Brasileiro | ACME LTDA
        <v-spacer></v-spacer>
        {{ $t('toolbar.timeZone') }}: BRT
      </div>

      <div class="my-auto d-flex" style="overflow: hidden">
        <v-img
          style="height: 100px; margin-right: -40px"
          aspect-ratio="16/9"
          :src="require(`./../assets/clearfx.ai-black.png`)"
          max-width="228"
          min-width="228"
          contain
          class="my-auto"
        >
        </v-img>

        <v-divider vertical inset class="mx-4"></v-divider>

        <div 
          class="my-auto mr-3 pr-3 font-weight-light text--secondary"
          style="font-size: 26px !important;"
        >
          {{ getRole }}
        </div>

        <v-spacer></v-spacer>

        <div
          class="d-flex align-center"
          style="overflow: auto hidden"
        >
          <v-slide-group
            class="d-flex mb-n3"
            style="width: 550px;"
            show-arrows
          >
            <v-slide-item
              v-for="(sparkline, ticker, index) in sparklineListIntraday"
              :key="index"
            >
              <sparkline-chart 
                :sparklineData="sparkline"
                :ticker="ticker"
                style="min-width: 150px;"
              >
              </sparkline-chart>
            </v-slide-item>
          </v-slide-group>

          <v-divider inset vertical></v-divider>

          <div>
            <economic-calendar></economic-calendar>
          </div>

          <v-divider inset vertical></v-divider>

          <div style="cursor: pointer;" v-ripple>
            <news-carousel></news-carousel>
          </div>

          <v-divider inset vertical></v-divider>

          <div>
            <avg-ptax></avg-ptax>
          </div>
        </div>

        <v-spacer></v-spacer>

        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-auto ml-12"
              v-bind="attrs"
              v-on="on"
              icon
            >
            <v-img
              v-if="isPtBRLocale"
              style="height: 32px;"
              content-class="rounded-lg"
              class="rounded-lg"
              aspect-ratio="16/9"
              max-width="32"
              width="32"
              :src="require(`./../assets/brazil_flag.png`)"
            />
            <v-img
              v-else
              style="height: 32px;"
              content-class="rounded-lg"
              class="rounded-lg"
              aspect-ratio="16/9"
              max-width="32"
              width="32"
              :src="require(`./../assets/usd_flag.png`)"
            />
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="changeLanguage('en')">
              <v-img
                style="height: 32px;"
                content-class="rounded-lg"
                class="rounded-lg mr-2"
                aspect-ratio="16/9"
                max-width="32"
                width="32"
                :src="require(`./../assets/usd_flag.png`)"
              />
              <v-list-item-title>
                EN-US
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="changeLanguage('pt')">
              <v-img
                style="height: 32px;"
                content-class="rounded-lg"
                class="rounded-lg mr-2"
                aspect-ratio="16/9"
                max-width="32"
                width="32"
                :src="require(`./../assets/brazil_flag.png`)"
              />
              <v-list-item-title>
                PT-BR
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-auto ml-5"
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>
                mdi-account
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="logoutClicked()">
              <v-list-item-title>
                {{ $t('instructions.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <template v-slot:extension>
      <v-tabs
        v-model="tab"
        dense
        background-color="#121212"
        :slider-size="5"
        :height="40"
        class="mt-3"
        style="border-bottom: 1px solid gray;"
        show-arrows
      >
        <v-tab
          v-for="(menu, index) in menus"
          :key="index"
          :disabled="menu.disabled"
          :to="menu.to"
          
        >
          <span 
            class="white--text text-capitalize"
            style="font-weight: 400;"
          >
            {{ menu.label }}
          </span>
        </v-tab>
      </v-tabs>
    </template>

    <v-snackbar
      color="attention"
      v-model="logoutError"
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="attention"
          text
          v-bind="attrs"
          @click="logoutError = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia"
// import { useAuthStore, CLEARFXAI_API_URL, SANDBOX_PATH } from "./../store/index"
import { useAlgoRFQStore, useAuthStore } from "./../store/index"
import EconomicCalendar from "./../components/EconomicCalendar.vue"
import NewsCarousel from "./../components/NewsCarousel.vue"
import AvgPtax from "./../components/AvgPtax.vue"
import SparklineChart from "./../components/SparklineChart.vue"

export default {
  components: {
    EconomicCalendar,
    NewsCarousel,
    AvgPtax,
    SparklineChart
  },
  data () {
    return {
      tab: 0,
      logoutError: false,
      errorMessage: undefined,
      // menus: [
      //   { label: this.$t('menus.fxmarket'), to: "/grid/fxmarket" },
      //   { label: this.$t('menus.trading'), to: "/grid/trading" },
      //   { label: this.$t('menus.reports'), to: "/grid/reports" },
      //   { label: "Monitoring (TBR)", disabled: true },
      //   { label: "IP Allowlist (TBR)", disabled: true },
      //   { label: "Session (TBR)", disabled: true },
      //   { label: "Notifications (TBR)", disabled: true }
      // ],
      eventSource: undefined,
      GRIDPretty: "Grid",
      GRIDPROPretty: "Grid PRO",
      intervalMinutely: null,
    }
  },
  mounted () {
    this.checkLastFetchHourlyAndUpdate()
    this.fetchMinutelyAndUpdate()
  },
  beforeDestroy () {
    clearInterval(this.intervalMinutely)
  },
  methods: {
    ...mapActions(useAuthStore, [
      "logout",
      "removeSessionLocalStorage",
      "getUserRoleFromLocalStorage"
    ]),
    ...mapActions(useAlgoRFQStore, [
      "getFxMarketDataHourly",
      "getFxMarketDataMinutely",
    ]),
    logoutClicked () {
      this.logout()
        .then(() => {
          this.authenticated = false
          this.userId = undefined
          this.removeSessionLocalStorage()
          this.$router.push("/")
        })
        .catch((error) => {
          this.logoutError = true
          this.errorMessage = "Unable to log out. Try again later."
          console.log(error)
        })
    },
    fetchFxMarketDataHourly(date_ptax = this.getDate(0, true)) {
      const from_date = this.getDate(0)
      const to_date = this.getDate(10)
      const countries = "US,EU,BR"

      this.getFxMarketDataHourly(from_date, to_date, countries, date_ptax)
        .then((response) => {
          const data = response.data
          const events = data["fx-market-economic-calendar"].result
          const sparklineListDaily = data["fx-market-chart-daily"]
          let avgPtax = data["avg-ptax"]

          if (avgPtax.error)  {
            date_ptax = this.getDate(-1, true)           
            return this.fetchFxMarketDataHourly(date_ptax)
          }
         
          this.fxMarketEconomicCalendar = events
          this.fxMarketChartDaily = sparklineListDaily
          this.avgPtax = avgPtax
         
          localStorage.setItem('fxMarketEconomicCalendar', JSON.stringify(events))
          localStorage.setItem('fxMarketChartDaily', JSON.stringify(sparklineListDaily))
          localStorage.setItem('avgPtax', JSON.stringify(avgPtax))

          const now = new Date()
          localStorage.setItem('lastFetchHourlyTime', now.toISOString())
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchFxMarketDataMinutely() {
      const topics = "economy_monetary,financial_markets,economy_macro,finance"
      const sort = "latest"
      const limit = "50"

      this.getFxMarketDataMinutely(topics, sort, limit)
        .then((response) => {
          const data = response.data
          const slidesNews = data["fx-market-news"]
          const sparklineListIntraday = data["fx-market-chart-intraday"]
          this.slidesNews = slidesNews
          this.fxMarketChartIntraday = sparklineListIntraday
          localStorage.setItem('slidesNews', JSON.stringify(slidesNews))
          localStorage.setItem('fxMarketChartIntraday', JSON.stringify(sparklineListIntraday))
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getDate(days, forAvgPtax = false) {
      const date = new Date()
      date.setDate(date.getDate() + days)

      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      if (forAvgPtax) {
        return `${day}/${month}/${year}`
      }

      return `${year}-${month}-${day}`
    },
    checkLastFetchHourlyAndUpdate() {
      const lastFetchHourlyTime = localStorage.getItem('lastFetchHourlyTime')
      const now = new Date()

      if (lastFetchHourlyTime) {
        const lastFetchDate = new Date(lastFetchHourlyTime)
        const timeDifference = (now - lastFetchDate) / (1000 * 60)

        if (timeDifference > 60) {
          this.fetchFxMarketDataHourly()
          return
        }
      } else {
        this.fetchFxMarketDataHourly()
      }
      this.fxMarketEconomicCalendar = JSON.parse(localStorage.getItem("fxMarketEconomicCalendar"))
      this.avgPtax = JSON.parse(localStorage.getItem("avgPtax"))
      this.fxMarketChartDaily = JSON.parse(localStorage.getItem("fxMarketChartDaily"))
    },
    fetchMinutelyAndUpdate () {
      this.fetchFxMarketDataMinutely()
      this.intervalMinutely = setInterval(() => {
        this.fetchFxMarketDataMinutely()
      }, 60000)
      this.slidesNews = JSON.parse(localStorage.getItem("slidesNews"))
      this.fxMarketChartIntraday = JSON.parse(localStorage.getItem("fxMarketChartIntraday"))
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('userLanguage', lang)
    },
  },
  computed: {
    ...mapWritableState(useAuthStore, [
      "authenticated",
      "userId"
    ]),
    ...mapWritableState(useAlgoRFQStore, [
      "fxMarketEconomicCalendar",
      "avgPtax",
      "fxMarketChartDaily",
      "slidesNews",
      "fxMarketChartIntraday",
    ]),
    ...mapState(useAlgoRFQStore, [
      "getFXMarketChartIntraday"
    ]),
    isPtBRLocale() {
      return this.$i18n.locale === "pt"
    },
    menus() {
      return [
        { label: this.$t("menus.fxmarket"), to: "/grid/fxmarket" },
        { label: this.$t("menus.trading"), to: "/grid/trading" },
        { label: this.$t("menus.reports"), to: "/grid/reports" },
      ]
    },
    getRole () {
      const titles = {
        grid: this.GRIDPretty,
        gridpro: this.GRIDPROPretty
      }
      const title = titles[this.getUserRoleFromLocalStorage()]
      return title
    },
    sparklineListIntraday () {
      if (!this.getFXMarketChartIntraday) return
      return this.getFXMarketChartIntraday.data
    }
  }
}
</script>

<style scoped>
::v-deep .v-toolbar__extension {
  height: 42px !important;
}
</style>
